<template>
  <div>
    <v-snackbar v-model="snackbar" multi-line timeout="6000"
      >Deine E-Mail-Adresse wurde aktualisiert. Wir haben dir eine E-Mail zur
      Verifizierung gesendet.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader icon="mdi-at" title="E-Mail"></Subheader>
                <div class="mt-7">
                  <v-card
                    :loading="emailForm.isLoading"
                    :disabled="emailForm.isDisabled"
                  >
                    <v-card-text
                      ><v-container>
                        <v-row>
                          <v-col cols="12">
                            <div class="subtitle-1">
                              Zur Anmeldung und für Mitteilungen betreffend das
                              Benutzerkonto wird die primäre E-Mail-Adresse
                              verwendet. Nach jeder Änderung der E-Mail-Adresse
                              muss diese erneut verifiziert werden.
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-form
                              v-model="emailForm.isValid"
                              @submit.prevent="updateEmail"
                            >
                              <v-text-field
                                v-model.trim="user.newEmail"
                                outlined
                                label="Primäre E-Mail-Adresse"
                                type="email"
                                :rules="[rules.required, rules.emailFormat]"
                                autocomplete="username"
                                required
                                hide-details="auto"
                                prepend-inner-icon="mdi-email"
                              ></v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                        <v-alert
                          v-if="emailForm.errorMessage !== ''"
                          dense
                          text
                          type="error"
                        >
                          {{ emailForm.errorMessage }}
                        </v-alert>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          @click="toggleReauthenticationDialog()"
                          :disabled="
                            !emailForm.isValid ||
                            user.newEmail == user.currentEmail
                          "
                        >
                          <v-icon left> mdi-email-edit </v-icon>
                          E-Mail-Adresse aktualisieren
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
          <reauthentication-dialog
            v-if="showReauthenticationDialog"
            @updateEmailAction="updateEmailAction"
            @close="toggleReauthenticationDialog()"
          ></reauthentication-dialog>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { auth } from "@/firebase";
import { getAuth, updateEmail, sendEmailVerification } from "firebase/auth";
import Subheader from "@/components/_systemwide/Subheader.vue";
import ReauthenticationDialog from "@/components/Auth/Reauthenticate.vue";
import { handleAuthError } from "@/util/firebase/FirebaseErrorHandling.js";
export default {
  name: "settings-emails",
  components: {
    Subheader,
    ReauthenticationDialog,
  },
  data() {
    return {
      emailForm: {
        isDisabled: false,
        isLoading: false,
        isValid: false,
        errorMessage: "",
      },
      user: {
        currentEmail: null,
        newEmail: null,
      },
      showReauthenticationDialog: false,
      snackbar: false,
      rules: {
        emailFormat: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
        required: (value) => !!value || "Erforderlich",
      },
    };
  },
  computed: {
    emailIcon() {
      if (auth.currentUser.emailVerified) {
        return "mdi-email-check";
      } else {
        return "mdi-email-remove";
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.user.currentEmail = auth.currentUser.email;
      this.user.newEmail = auth.currentUser.email;
    },
    toggleReauthenticationDialog() {
      this.showReauthenticationDialog = !this.showReauthenticationDialog;
    },
    updateEmailAction() {
      this.emailForm.isDisabled = true;
      this.emailForm.isLoading = true;
      const auth = getAuth();
      updateEmail(auth.currentUser, this.user.newEmail)
        .then(() => {
          return sendEmailVerification(auth.currentUser);
        })
        .then(() => {
          this.emailForm.isDisabled = false;
          this.emailForm.isLoading = false;
          this.snackbar = true;
        })
        .catch((error) => {
          this.emailForm.errorMessage = handleAuthError(error.code);
          console.log(error.code, error.message);
          this.emailForm.isDisabled = false;
          this.emailForm.isLoading = false;
        });
    },
  },
};
</script>
