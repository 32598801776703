<template>
  <div class="modal">
    <v-snackbar v-model="snackbar" multi-line timeout="-1"
      >Passwort erfolgreich zurückgesetzt! Wir haben Dir eine E-Mail mit einem
      Link gesendet, um dein Passwort zu ändern.
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Passwort zurücksetzen</span>
          </v-card-title>
          <v-card-text v-if="!showSuccess">
            <body>
              Wir senden Dir einen Link an die mit deinem Konto verknüpfte
              E-Mail Adresse. Öffne den Link, um dein Passwort zu ändern.
            </body>
            <v-spacer style="height: 14px"></v-spacer>
            <v-form
              v-model="formIsValid"
              ref="form"
              @submit.prevent="resetPassword"
            >
              <v-text-field
                v-model.trim="email"
                type="email"
                outlined
                :rules="[rules.required, rules.emailFormat]"
                placeholder="name@email.de"
                label="E-Mail-Adresse"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="errorMessage !== ''">
            <v-alert dense text type="error"> {{ errorMessage }} </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              :disabled="!formIsValid"
              @click="resetPassword()"
              >Passwort zurücksetzen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import { handleAuthError } from "@/util/firebase/FirebaseErrorHandling.js";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Erforderlich",
        emailFormat: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
      },
      formIsValid: false,
      email: "",
      showSuccess: false,
      errorMessage: "",
      dialog: true,
      snackbar: false,
    };
  },
  methods: {
    async resetPassword() {
      this.errorMessage = "";
      try {
        await auth.sendPasswordResetEmail(this.email);
        this.showSuccess = true;
        this.dialog = false;
        this.snackbar = true;
      } catch (error) {
        this.errorMessage = handleAuthError(error.code);
        console.log(error.code, error.message);
      }
    },
  },
};
</script>
