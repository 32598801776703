<template>
  <div>
    <section class="hidden-lg-and-up mt-6">
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar
                      ><v-icon large
                        >mdi-account-circle</v-icon
                      ></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        displayName
                      }}</v-list-item-title>
                      <!-- <v-list-item-subtitle>Verfügbar</v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn depressed @click="drawer = !drawer"
                        >Weitere Einstellungen
                        <v-icon>mdi-menu-down</v-icon></v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-navigation-drawer
          v-model="drawer"
          class="rounded elevation-3 ma-lg-6"
          app
          bottom
          clipped
          floating
          style="height: auto"
        >
          <v-list nav>
            <v-list-item>
              <v-list-item-avatar
                ><v-icon large>mdi-account-circle</v-icon></v-list-item-avatar
              >
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">{{
                  displayName
                }}</v-list-item-title>
                <!-- <v-list-item-subtitle>Verfügbar</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group>
              <template v-for="(item, index) in items">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider v-else-if="item.divider" :key="index"></v-divider>

                <v-list-item
                  v-else
                  :key="index"
                  link
                  :to="item.route"
                  :disabled="item.disabled"
                  dense
                  active-class="font-weight-medium"
                >
                  <v-list-item-icon>
                    <v-icon dense>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="font-weight-normal">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <router-view class="pl-lg-6" :user="user" />
      </template>
    </section>
  </div>
</template>

<script>
import { auth, db } from "@/firebase";

export default {
  name: "settings",

  data() {
    return {
      drawer: null,
      items: [
        {
          icon: "mdi-account-outline",
          name: "Stammdaten",
          route: { name: "settings-profile" },
        },
        {
          icon: "mdi-domain",
          name: "Organisationen",
          route: { name: "settings-organizations" },
        },
        { divider: true },
        { header: "Einstellungen" },
        {
          icon: "mdi-brush-outline",
          name: "Erscheinungsbild",
          disabled: true,
          route: { name: "settings-appearance" },
        },
        {
          icon: "mdi-human",
          name: "Bedienungshilfen",
          disabled: false,
          route: { name: "settings-accessability" },
        },
        // {
        //   icon: "mdi-card-account-mail-outline",
        //   name: "Erreichbarkeiten",
        //   disabled: true,
        //   route: { name: "settings-contact" },
        // },
        {
          icon: "mdi-bell-outline",
          name: "Mitteilungen",
          route: { name: "settings-notifications" },
        },
        {
          icon: "mdi-account-eye-outline",
          name: "Datenschutz",
          route: { name: "settings-privacy" },
        },

        { divider: true },
        { header: "Zugangsdaten" },
        {
          icon: "mdi-email-outline",
          name: "E-Mail-Adressen",
          route: { name: "settings-emails" },
        },
        {
          icon: "mdi-shield-account-outline",
          name: "Authentifizierung",
          route: { name: "settings-security" },
        },
      ],
      user: {
        firstName: "",
        lastName: "",
        settings: {
          preferedOrg: { id: "" },
          appearance: "auto",
          accessability: { useColorblindMode: false },
          language: "de",
          notifications: {
            email: {
              activeTriggers: [],
            },
          },
        },
        email: "",
        privacy: {
          web: {
            performanceMonitoring: {
              usage: false,
              consentTimestamp: {},
            },
            googleAnalytics: {
              usage: false,
              consentTimestamp: {},
            },
          },
        },
        uiConfig: {},
        meta: {
          isSupport: true,
          accountType: "user",
          uid: "",
        },
      },
    };
  },
  computed: {
    displayName() {
      return (
        this.$store.state.user.user.firstName +
        " " +
        this.$store.state.user.user.lastName
      );
    },
  },
  watch: {
    $route: "initialize",
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      db.collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.user = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.error("Error: ", error);
        });
    },
  },
};
</script>
