<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              v-model="formIsValid"
              @submit.prevent="updateInfo"
              class="col s8"
            >
              <v-col cols="12">
                <Subheader
                  icon="mdi-card-account-details"
                  title="Stammdaten"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text
                      ><v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model.trim="user.firstName"
                              outlined
                              label="Vorname"
                              required
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model.trim="user.lastName"
                              outlined
                              label="Nachname"
                              required
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            color="success"
                            @click="updateInfo()"
                          >
                            <v-icon left> mdi-content-save </v-icon>
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-account-minus"
                  title="Konto löschen"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <div class="subtitle-1">
                        Beim Löschen deines Benutzerkontos wird dieses dauerhaft
                        und unwiderruflich gelöscht. Die anschließende
                        Wiederherstellung ist nicht möglich. Du kannst dein
                        Benutzerkonto nur löschen, wenn keine Mitgliedschaften
                        in Organisationen mehr bestehen.
                      </div>
                      <v-btn
                        color="error"
                        depressed
                        text
                        class="mt-3"
                        @click="toggleAccountDeletionDialog()"
                      >
                        <v-icon left> mdi-account-minus </v-icon>
                        Benutzerkonto dauerhaft löschen
                      </v-btn>
                      <CustomDialog
                        v-if="helpers.accountDeletionDialog.model"
                        @close="toggleAccountDeletionDialog()"
                        title="Benutzerkonto dauerhaft löschen"
                        text="Bist Du sicher, dass Du dein Benutzerkonto dauerhaft löschen möchtest? Es wird dabei dauerhaft und unwiderruflich gelöscht."
                        :loading="helpers.accountDeletionDialog.loading"
                        :btnAction="deleteAccount"
                        :errorMessage="
                          helpers.accountDeletionDialog.errorMessage
                        "
                        btnColor="error"
                        width="s"
                      ></CustomDialog>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { auth, functions } from "@/firebase";
import { USER } from "@/store/modules.js";
import { UPDATE_USER_DETAILS, SIGN_OUT } from "@/store/action-types.js";

export default {
  name: "settings-profile",
  components: {
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      data: null,

      formIsValid: false,

      helpers: {
        accountDeletionDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  props: {
    user: Object,
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    updateInfo() {
      if (this.formIsValid) {
        this.$store.dispatch(`${USER}${UPDATE_USER_DETAILS}`, {
          uid: auth.currentUser.uid,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    toggleAccountDeletionDialog() {
      this.helpers.accountDeletionDialog.model =
        !this.helpers.accountDeletionDialog.model;
    },
    deleteAccount() {
      this.helpers.accountDeletionDialog.loading = true;
      const callFunction = functions.httpsCallable("auth-deleteAccount");
      callFunction({ licenseKey: this.licenseKey })
        .then((result) => {
          if (result.data.success) {
            console.log("signout – account deleted");
          }
          this.$store.dispatch(`${USER}${SIGN_OUT}`);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          console.error(error.message);
          this.helpers.accountDeletionDialog.errorMessage = error.message;
          this.helpers.accountDeletionDialog.loading = false;
        });
    },
  },
};
</script>
