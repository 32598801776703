<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-server-security"
                  title="Auskunftsrecht gem. Art. 15 DSGVO"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <div class="subtitle-1">
                        Die Verarbeitung von personenbezogenen Daten durch die
                        Mira Digital Solutions UG (haftungsbeschränkt) erfolgt
                        nach den Maßgaben der geltenden datenschutzrechtlichen
                        Bestimmungen. Gemäß Art. 15 Abs. 3 DSGVO stellen wir Dir
                        eine Kopie der personenbezogenen Daten, die Gegenstand
                        der Verarbeitung sind, zur Verfügung. Du kannst den
                        Antrag elektronisch stellen und erhälst diesen in einem
                        gängigen elektronischen Format zurück.
                      </div>
                      <v-btn
                        color="success"
                        text
                        class="mt-3"
                        @click="askForDataExport()"
                      >
                        <v-icon left> mdi-database-export </v-icon>
                        Datenexport beantragen
                      </v-btn>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div class="subtitle-1">
                        Kontaktiere uns, solltest Du zudem weitere Informationen
                        oder Unterstützung benötigen.
                      </div>
                      <v-btn
                        color="success"
                        text
                        class="mt-3"
                        @click="contactViaMail()"
                      >
                        <v-icon left> mdi-at </v-icon>
                        Kontakt aufnehmen
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-google-analytics"
                  title="Analyse des Benutzerverhaltens mithilfe von
                  Google Analytics"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <div class="subtitle-1">
                        Zur Verbesserung unserer Produkte setzen wir Google
                        Analytics zur Analyse ein. Dabei werden verschiedene
                        Daten gesammelt, die helfen das Benutzerverhalten
                        auszuwerten und so für die Optimierung der Dienste und
                        Benutzererlebnis eingesetzt werden. Es erfolgt keine
                        Verknüpfung mit deiner Identität oder personenbezogenen
                        Daten. Deine IP-Adresse wird anonymisiert. Du kannst
                        Deine Einwilligung jederzeit widerrufen.
                      </div>
                      <v-checkbox
                        label="Einwilligung in die Analyse des Benutzerverhaltens mittels Google Analytics"
                        v-model="useGA"
                      ></v-checkbox>
                      <div v-if="useGA">
                        Deine Einwilligung erfolgte am {{ consentDateGA }}.
                      </div>
                      <!-- <div v-else>Du hast der Analyse nicht zugestimmt.</div> -->
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-gauge"
                  title="Leistungsüberwachung mithilfe von
                  Firebase Performance Monitoring"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <div class="subtitle-1">
                        Zur Verbesserung unserer Produkte setzen wir Firebase
                        Performance Monitoring zur Leistungsüberwachung ein.
                        Dabei werden verschiedene Daten insbesondere betreffend
                        die Stabilität, Geschwindigkeit und Netzwerkanfragen der
                        Anwendung gesammelt, die helfen die Leistung der
                        Anwendung auszuwerten, Leistungsprobleme zu beheben und
                        auf diese Weise das Benutzererlebnis zu optimieren. Es
                        erfolgt keine Verknüpfung mit deiner Identität oder
                        personenbezogenen Daten. Es werden lediglich technische
                        Parameter analyisiert. Du kannst Deine Einwilligung
                        jederzeit widerrufen.
                      </div>
                      <v-checkbox
                        label="Einwilligung in die Analyse der Anwendung mittels Firebase Performance Monitoring"
                        v-model="usePA"
                      ></v-checkbox>
                      <div v-if="usePA">
                        Deine Einwilligung erfolgte am {{ consentDatePA }}.
                      </div>
                      <!-- <div v-else>Du hast der Analyse nicht zugestimmt.</div> -->
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-file-document-multiple"
                  title="Datenschutzerklärung und AGB"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <div class="subtitle-1">
                        Wir verweisen weiter auf die
                        <a
                          href="https://docs.mira-one.com/datenschutz"
                          class="text-decoration-none"
                          >Datenschutzerklärung</a
                        >
                        sowie
                        <a
                          href="https://docs.mira-one.com/agb"
                          class="text-decoration-none"
                          >allgemeinen Geschäftsbedingungen</a
                        >.
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { auth } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "profile-tab-privacy",
  components: {
    Subheader,
  },
  data() {
    return {
      useGA: false,
      consentDateGA: "date",

      usePA: false,
      consentDatePA: "date",
    };
  },
  methods: {
    askForDataExport() {
      let displayName = auth.currentUser.displayName;
      let email = auth.currentUser.email;
      let uid = auth.currentUser.uid;
      let provider = auth.currentUser.providerId;
      let mailto =
        "mailto:support@mira-one.com?cc=datenschutz@mira-one.com&subject=Auskunftsrecht%20gem.%20Art.%C2%A020%20DSGVO&body=Sehr%20geehrte%20Damen%20und%20Herren%2C%0A%0Ahiermit%20m%C3%B6chte%20ich%20von%20meinem%20Auskunftsrecht%20gem.%20Art.%C2%A015%20DSGVO%20Gebrauch%20machen%20und%20bitte%20um%20Erhalt%20einer%20Kopie%20der%20mich%20betreffenden%20personenbezogenen%20Daten%2C%20die%20erhoben%20worden%20sind.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%0A%0A" +
        displayName +
        "%0A%0AEmail%3A%20" +
        email +
        "%0AUID%3A%20" +
        uid +
        "%0AProvider%3A%20" +
        provider;
      window.location.href = mailto;
    },
    contactViaMail() {
      window.location.href =
        "mailto:support@mira-one.com?subject=Kontaktaufnahme%20bzgl.%20Datenschutz";
    },
  },
};
</script>
