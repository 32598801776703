<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-brush"
                  title="Erscheinungsbild"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="$i18n.locale"
                              :items="$i18n.availableLocales"
                              outlined
                              single-line
                              disabled
                              label="Sprache"
                              hint="Die Anwendung, Benachrichtigungen etc. verwenden diese Sprache."
                              persistent-hint
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="appearance"
                              :items="appearanceModes"
                              outlined
                              single-line
                              disabled
                              label="Erscheinungsbild"
                              hint="Von dieser Einstellung ist die Farbpalette abhängig, die bei der Darstellung der Anwendung ausgewählt wird."
                              persistent-hint
                            >
                              <template v-slot:item="data">
                                <v-list-item>
                                  <v-list-item-action>
                                    <v-icon>
                                      {{ data.item.icon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ data.item.text }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:selection="data">
                                <v-list-item>
                                  <v-list-item-action>
                                    <v-icon>
                                      {{ data.item.icon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ data.item.text }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            color="success"
                            :loading="loading"
                            disabled
                            @click="updateSettings"
                          >
                            <v-icon left> mdi-content-save </v-icon>
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
// import { db, auth } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "settings-appearance",
  components: {
    Subheader,
  },
  data() {
    return {
      loading: false,
      appearance: "",
      appearanceModes: [
        {
          text: "Automatisch (wie System)",
          value: "auto",
          icon: "mdi-desktop-tower-monitor",
        },
        {
          text: "Helles Erscheinungsbild",
          value: "light",
          icon: "mdi-white-balance-sunny",
        },
        {
          text: "Dunkles Erscheinungsbild",
          value: "dark",
          icon: "mdi-weather-night",
        },
      ],
    };
  },
  watch: {
    "$store.state.user.user.settings.appearance"(value) {
      this.appearance = value;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.appearance = this.$store.state.user.user.settings.appearance;
    },
    updateSettings() {
      // this.loading = true;
      // var hintRef = db.collection("users").doc(auth.currentUser.uid);
      // hintRef
      //   .update({
      //     "settings.appearance": this.appearance,
      //   })
      //   .then(() => {
      //     this.loading = false;
      //     this.$store.dispatch(`${USER}${GET_USER_PROFILE}`, {
      //       uid: auth.currentUser.uid,
      //       allowRedirect: false,
      //     });
      //     // Analytics event -> appearance(light/dark/auto)
      //   })
      //   .catch(function (error) {
      //     console.error("Error updating document: ", error);
      //   });
    },
  },
};
</script>
