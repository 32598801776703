<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-form-textbox-password"
                  title="Benutzerpasswort"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <div class="subtitle-1">
                        Um die Änderung Deines Benutzerpassworts anzufordern,
                        benötigest Du Zugang zu Deinem E-Mail-Postfach.
                      </div>
                      <v-btn
                        color="primary"
                        depressed
                        text
                        class="mt-3"
                        @click="togglePasswordReset()"
                      >
                        <v-icon left> mdi-lock-reset </v-icon>
                        Passwortänderung anfordern
                      </v-btn>
                      <v-container>
                        <PasswordReset
                          v-if="helpers.passwordResetDialog.model"
                          @close="togglePasswordReset()"
                        ></PasswordReset>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
          <v-row>
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-two-factor-authentication"
                  title="Zwei-Faktor-Authentifizierung"
                ></Subheader>
                <div class="mt-7">
                  <v-alert
                    type="info"
                    border="left"
                    colored-border
                    elevation="2"
                    class="mt-6"
                    >Demnächst verfügbar
                  </v-alert>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import PasswordReset from "@/components/Auth/PasswordReset";
export default {
  name: "settings-security",
  components: {
    Subheader,
    PasswordReset,
  },
  data() {
    return {
      helpers: {
        passwordResetDialog: {
          model: false,
        },
      },
    };
  },
  methods: {
    togglePasswordReset() {
      this.helpers.passwordResetDialog.model =
        !this.helpers.passwordResetDialog.model;
    },
  },
};
</script>
