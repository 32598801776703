<template>
  <div class="modal">
    <v-snackbar v-model="snackbar" timeout="3000"
      >Authentifizierung erfolgreich. Aktion wird ausgeführt.
    </v-snackbar>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Identität bestätigen</span>
          </v-card-title>
          <v-card-text v-if="!showSuccess">
            <div class="body-2 my-2">
              Aus Sicherheitsgründen musst Du deine Identität bestätigen, um
              diese Aktion auszuführen.
            </div>
            <v-spacer style="height: 14px"></v-spacer>
            <v-form
              v-model="formIsValid"
              ref="form"
              @submit.prevent="reauthenticate"
            >
              <v-text-field
                v-model.trim="password"
                type="password"
                autocomplete="current-password"
                outlined
                placeholder="••••••••••••••••"
                :rules="[rules.required]"
                label="Passwort"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="errorMessage !== ''">
            <v-alert dense text type="error"> {{ errorMessage }} </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              :disabled="!formIsValid"
              @click="reauthenticate()"
              >Identität bestätigen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { auth, EmailAuthProvider } from "@/firebase";
export default {
  name: "reauthentication-dialog",
  data() {
    return {
      rules: { required: (value) => !!value || "Erforderlich" },
      formIsValid: false,
      password: null,
      showSuccess: false,
      errorMessage: "",
      dialog: true,
      snackbar: false,
    };
  },
  methods: {
    reauthenticate() {
      var user = auth.currentUser;
      var credential = EmailAuthProvider.credential(user.email, this.password);
      // Prompt the user to re-provide their sign-in credentials
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          // User re-authenticated.
          this.snackbar = true;
          this.showSuccess = true;
          this.dialog = false;
          return this.$emit("updateEmailAction");
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
          this.errorMessage = error.message;
        });
    },
  },
};
</script>
