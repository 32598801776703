/** Returns an error message based on the error code for Firebase Auth.
 *
 * @param {string} error Firebase Auth error code
 * @returns error message
 */
export function handleAuthError(error) {
  switch (error) {
    case "auth/admin-restricted-operation":
      return "Fehler: Diese Operation ist nur für Administratoren zugänglich.";

    case "auth/argument-error":
      return "Fehler: Ungültiges Argument.";

    case "auth/app-not-authorized":
      return "Fehler: Die App ist nicht autorisiert.";

    case "auth/app-not-installed":
      return "Fehler: Die App ist nicht installiert.";

    case "auth/captcha-check-failed":
      return "Fehler: Die Überprüfung des Captchas ist fehlgeschlagen.";

    case "auth/code-expired":
      return "Fehler: Der Code ist abgelaufen.";

    case "auth/cordova-not-ready":
      return "Fehler: Cordova ist nicht bereit.";

    case "auth/cors-unsupported":
      return "Fehler: CORS wird nicht unterstützt.";

    case "auth/credential-already-in-use":
      return "Fehler: Die Anmeldeinformationen werden bereits verwendet.";

    case "auth/custom-token-mismatch":
      return "Fehler: Die Anmeldeinformationen stimmen nicht überein.";

    case "auth/requires-recent-login":
      return "Fehler: Anmeldung erforderlich. Bitte melde dich erneut an.";

    case "auth/dependent-sdk-initialized-before-auth":
      return "Fehler: Abhängiges SDK wurde vor der Authentifizierung initialisiert.";

    case "auth/dynamic-link-not-activated":
      return "Fehler: Dynamischer Link ist nicht aktiviert.";

    case "auth/email-change-needs-verification":
      return "Fehler: Die E-Mail-Änderung erfordert eine Überprüfung.";

    case "auth/email-already-in-use":
      return "Fehler: Diese E-Mail-Adresse wird bereits verwendet.";

    case "auth/emulator-config-failed":
      return "Fehler: Emulator-Konfiguration fehlgeschlagen.";

    case "auth/expired-action-code":
      return "Fehler: Ablaufdatum des Aktionscodes erreicht.";

    case "auth/cancelled-popup-request":
      return "Fehler: Popup-Anforderung abgebrochen.";

    case "auth/internal-error":
      return "Fehler: Interner Fehler.";

    case "auth/invalid-api-key":
      return "Fehler: Ungültiger API-Schlüssel.";

    case "auth/invalid-app-credential":
      return "Fehler: Ungültige App-Anmeldeinformationen.";

    case "auth/invalid-app-id":
      return "Fehler: Ungültige App-ID.";

    case "auth/invalid-user-token":
      return "Fehler: Ungültiges Benutzer-Token.";

    case "auth/invalid-auth-event":
      return "Fehler: Ungültiges Authentifizierungsereignis.";

    case "auth/invalid-verification-code":
      return "Fehler: Ungültiger Bestätigungscode. Bitte überprüfe den Code und versuche es erneut.";

    case "auth/invalid-continue-uri":
      return "Fehler: Ungültige Weiterleitungs-URI. Überprüfe die Weiterleitungs-URI und versuche es erneut.";

    case "auth/invalid-cordova-configuration":
      return "Fehler: Ungültige Cordova-Konfiguration. Überprüfe die Konfiguration und versuche es erneut.";

    case "auth/invalid-custom-token":
      return "Fehler: Ungültiges benutzerdefiniertes Token. Stelle sicher, dass das Token korrekt ist und versuche es erneut.";

    case "auth/invalid-dynamic-link-domain":
      return "Fehler: Ungültige Dynamic Link-Domäne. Überprüfe die Domäne und versuche es erneut.";

    case "auth/invalid-email":
      return "Fehler: Ungültige E-Mail-Adresse. Bitte überprüfe die E-Mail-Adresse und versuche es erneut.";

    case "auth/invalid-emulator-scheme":
      return "Fehler: Ungültiges Emulator-Schema. Überprüfe das Schema und versuche es erneut.";

    case "auth/invalid-credential":
      return "Fehler: Ungültige Anmeldedaten. Überprüfe die Anmeldedaten und versuche es erneut.";

    case "auth/invalid-message-payload":
      return "Fehler: Ungültiges Nachrichten-Payload. Überprüfe das Payload und versuche es erneut.";

    case "auth/invalid-multi-factor-session":
      return "Fehler: Ungültige Multi-Faktor-Sitzung. Überprüfe die Sitzung und versuche es erneut.";

    case "auth/invalid-oauth-client-id":
      return "Fehler: Ungültige OAuth-Client-ID. Überprüfe die Client-ID und versuche es erneut.";

    case "auth/invalid-oauth-provider":
      return "Fehler: Ungültiger OAuth-Anbieter. Überprüfe den Anbieter und versuche es erneut.";

    case "auth/invalid-action-code":
      return "Fehler: Ungültiger Aktionscode. Überprüfe den Code und versuche es erneut.";

    case "auth/unauthorized-domain":
      return "Fehler: Nicht autorisierte Domain. Überprüfe die Domain und versuche es erneut.";

    case "auth/wrong-password":
      return "Fehler: Falsches Passwort. Überprüfe das Passwort und versuche es erneut.";

    case "auth/invalid-persistence-type":
      return "Fehler: Ungültiger Persistenztyp. Überprüfe den Typ und versuche es erneut.";

    case "auth/invalid-phone-number":
      return "Fehler: Ungültige Telefonnummer. Überprüfe die Telefonnummer und versuche es erneut.";

    case "auth/invalid-provider-id":
      return "Fehler: Ungültige Anbieter-ID. Überprüfe die ID und versuche es erneut.";

    case "auth/invalid-recipient-email":
      return "Fehler: Ungültige Empfänger-E-Mail. Überprüfe die E-Mail und versuche es erneut.";

    case "auth/invalid-sender":
      return "Fehler: Ungültiger Absender. Überprüfe den Absender und versuche es erneut.";

    case "auth/invalid-verification-id":
      return "Fehler: Ungültige Bestätigungs-ID. Überprüfe die ID und versuche es erneut.";

    case "auth/invalid-tenant-id":
      return "Fehler: Ungültige Mandanten-ID. Überprüfe die ID und versuche es erneut.";

    case "auth/multi-factor-info-not-found":
      return "Fehler: Multi-Faktor-Informationen nicht gefunden. Überprüfe die Informationen und versuche es erneut.";

    case "auth/multi-factor-auth-required":
      return "Fehler: Multi-Faktor-Authentifizierung erforderlich. Aktiviere die Multi-Faktor-Authentifizierung und versuche es erneut.";

    case "auth/missing-android-pkg-name":
      return "Fehler: Fehlender Android-Paketname. Überprüfe den Paketnamen und versuche es erneut.";

    case "auth/missing-app-credential":
      return "Fehler: Fehlende App-Anmeldeinformation. Überprüfe die Anmeldeinformationen und versuche es erneut.";

    case "auth/auth-domain-config-required":
      return "Fehler: Authentifizierungsdomänenkonfiguration erforderlich. Konfiguriere die Domäne und versuche es erneut.";

    case "auth/missing-verification-code":
      return "Fehler: Fehlender Bestätigungscode. Überprüfe den Code und versuche es erneut.";

    case "auth/missing-continue-uri":
      return "Fehler: Fehlende Weiterleitungs-URI. Überprüfe die URI und versuche es erneut.";

    case "auth/missing-iframe-start":
      return "Fehler: Start des IFrames fehlt. Bitte überprüfe deine Konfiguration.";

    case "auth/missing-ios-bundle-id":
      return "Fehler: iOS-Bundle-ID fehlt. Bitte überprüfe deine Konfiguration.";

    case "auth/missing-or-invalid-nonce":
      return "Fehler: Nonce fehlt oder ist ungültig. Bitte überprüfe deine Konfiguration.";

    case "auth/missing-multi-factor-info":
      return "Fehler: Multi-Faktor-Informationen fehlen. Bitte überprüfe deine Konfiguration.";

    case "auth/missing-multi-factor-session":
      return "Fehler: Multi-Faktor-Sitzung fehlt. Bitte überprüfe deine Konfiguration.";

    case "auth/missing-phone-number":
      return "Fehler: Telefonnummer fehlt. Bitte überprüfe deine Eingabe.";

    case "auth/missing-verification-id":
      return "Fehler: Verifikations-ID fehlt. Bitte überprüfe deine Konfiguration.";

    case "auth/app-deleted":
      return "Fehler: App gelöscht. Bitte wende dich an den Support.";

    case "auth/account-exists-with-different-credential":
      return "Fehler: Konto existiert bereits mit anderen Anmeldedaten. Bitte bestätige dein Konto.";

    case "auth/network-request-failed":
      return "Fehler: Netzwerkanfrage fehlgeschlagen. Bitte überprüfe deine Internetverbindung.";

    case "auth/null-user":
      return "Fehler: Benutzer ist null. Bitte versuche es erneut oder kontaktiere den Support.";

    case "auth/no-auth-event":
      return "Fehler: Kein Authentifizierungsereignis. Bitte versuche es erneut oder kontaktiere den Support.";

    case "auth/no-such-provider":
      return "Fehler: Anbieter nicht gefunden. Bitte überprüfe deine Konfiguration.";

    case "auth/operation-not-allowed":
      return "Fehler: Operation nicht erlaubt. Bitte kontaktiere den Support.";

    case "auth/operation-not-supported-in-this-environment":
      return "Fehler: Operation wird in dieser Umgebung nicht unterstützt. Bitte überprüfe deine Konfiguration.";

    case "auth/popup-blocked":
      return "Fehler: Popup blockiert. Bitte aktiviere Popups in deinem Browser.";

    case "auth/popup-closed-by-user":
      return "Fehler: Popup vom Benutzer geschlossen. Bitte versuche es erneut.";

    case "auth/rejected-credential":
      return "Fehler: Die Anmeldeinformationen wurden abgelehnt. Bitte überprüfe deine Eingaben und versuche es erneut.";

    case "auth/second-factor-already-in-use":
      return "Fehler: Die zweistufige Authentifizierung ist bereits aktiviert.";

    case "auth/maximum-second-factor-count-exceeded":
      return "Fehler: Die maximale Anzahl von zweiten Authentifizierungsfaktoren wurde überschritten.";

    case "auth/tenant-id-mismatch":
      return "Fehler: Die Mandanten-ID stimmt nicht überein.";

    case "auth/timeout":
      return "Fehler: Zeitüberschreitung beim Authentifizierungsvorgang. Versuche es erneut.";

    case "auth/user-token-expired":
      return "Fehler: Die Anmeldeinformationen sind abgelaufen. Bitte melde dich erneut an.";

    case "auth/too-many-requests":
      return "Fehler: Zu viele Anfragen. Versuche es später erneut.";

    case "auth/unauthorized-continue-uri":
      return "Fehler: Die Weiterleitungs-URI ist nicht autorisiert.";

    case "auth/unsupported-first-factor":
      return "Fehler: Der erste Authentifizierungsfaktor wird nicht unterstützt.";

    case "auth/unsupported-persistence-type":
      return "Fehler: Der angegebene Persistenztyp wird nicht unterstützt.";

    case "auth/unsupported-tenant-operation":
      return "Fehler: Die Mandantenoperation wird nicht unterstützt.";

    case "auth/unverified-email":
      return "Fehler: Die E-Mail-Adresse wurde noch nicht verifiziert. Bitte bestätige deine E-Mail-Adresse.";

    case "auth/user-cancelled":
      return "Fehler: Der Benutzer hat den Vorgang abgebrochen.";

    case "auth/user-not-found":
      return "Fehler: Der Benutzer wurde nicht gefunden. Bitte überprüfe die eingegebene E-Mail-Adresse.";

    case "auth/user-disabled":
      return "Fehler: Der Benutzeraccount ist deaktiviert. Kontaktiere den Support für weitere Hilfe.";

    case "auth/user-mismatch":
      return "Fehler: Ein Benutzerkonflikt ist aufgetreten. Bitte melde dich erneut an.";

    case "auth/user-signed-out":
      return "Fehler: Der Benutzer hat sich abgemeldet.";

    case "auth/weak-password":
      return "Fehler: Das Passwort ist zu schwach. Verwende ein sichereres Passwort.";

    case "auth/web-storage-unsupported":
      return "Fehler: Die Web-Speicherfunktion wird auf diesem Gerät nicht unterstützt.";

    case "auth/already-initialized":
      return "Fehler: Die Authentifizierung ist bereits initialisiert.";

    case "auth/recaptcha-not-enabled":
      return "Fehler: reCAPTCHA ist nicht aktiviert. Bitte wende dich an den Support.";

    case "auth/missing-recaptcha-token":
      return "Fehler: Das reCAPTCHA-Token fehlt. Bitte versuche es erneut.";

    case "auth/invalid-recaptcha-token":
      return "Fehler: Das reCAPTCHA-Token ist ungültig. Bitte versuche es erneut.";

    case "auth/invalid-recaptcha-action":
      return "Fehler: Die reCAPTCHA-Aktion ist ungültig. Bitte versuche es erneut.";

    case "auth/missing-client-type":
      return "Fehler: Der Client-Typ fehlt. Bitte versuche es erneut.";

    case "auth/missing-recaptcha-version":
      return "Fehler: Die reCAPTCHA-Version fehlt. Bitte versuche es erneut.";

    case "auth/invalid-recaptcha-version":
      return "Fehler: Die reCAPTCHA-Version ist ungültig. Bitte versuche es erneut.";

    case "auth/invalid-req-type":
      return "Fehler: Der Anforderungstyp ist ungültig. Bitte versuche es erneut.";

    default:
      return "Fehler: Ein Fehler ist aufgetreten. Versuche es später erneut oder kontaktiere den Support.";
  }
}

export function handleStorageError(error) {
  switch (error.code) {
    case "storage/unknown":
      return "Fehler: Ein unbekannter Fehler ist aufgetreten.";
    case "storage/object-not-found":
      return "Fehler: Kein Objekt existiert an der angegebenen Referenz.";
    case "storage/bucket-not-found":
      return "Fehler: Kein Bucket ist für Cloud Storage konfiguriert.";
    case "storage/project-not-found":
      return "Fehler: Kein Projekt ist für Cloud Storage konfiguriert.";
    case "storage/quota-exceeded":
      return "Fehler: Das Kontingent für Ihren Cloud Storage-Bucket wurde überschritten.";
    case "storage/unauthenticated":
      return "Fehler: Der Benutzer ist nicht authentifiziert. Bitte authentifiziere dich und versuche es erneut.";
    case "storage/unauthorized":
      return "Fehler: Der Benutzer ist nicht berechtigt, die angeforderte Aktion auszuführen.";
    case "storage/retry-limit-exceeded":
      return "Fehler: Das Zeitlimit für eine Operation (Hochladen, Herunterladen, Löschen usw.) wurde überschritten. Versuche es erneut.";
    case "storage/invalid-checksum":
      return "Fehler: Die Datei auf dem Client stimmt nicht mit der Prüfsumme der vom Server empfangenen Datei überein. Versuche es erneut.";
    case "storage/canceled":
      return "Fehler: Der Benutzer hat die Operation abgebrochen.";
    case "storage/invalid-event-name":
      return "Fehler: Ungültiger Ereignisname angegeben.";
    case "storage/invalid-url":
      return "Fehler: Ungültige URL übergeben.";
    case "storage/invalid-argument":
      return "Fehler: Das übergebene Argument hat nicht den richtigen Typ.";
    case "storage/no-default-bucket":
      return "Fehler: Kein Bucket wurde in der storageBucket-Eigenschaft festgelegt.";
    case "storage/cannot-slice-blob":
      return "Fehler: Tritt häufig auf, wenn die lokale Datei geändert wurde (gelöscht, erneut gespeichert usw.). Versuche es erneut, nachdem du überprüft hast, dass die Datei nicht geändert wurde.";
    case "storage/server-file-wrong-size":
      return "Fehler: Die Datei auf dem Client stimmt nicht mit der Größe der vom Server empfangenen Datei überein. Versuche es erneut.";
    default:
      return "Fehler: Ein Fehler ist aufgetreten. Versuche es später erneut oder kontaktiere den Support.";
  }
}

/** Returns an error message based on the error code for Firestore.
 *
 * @param {string} error Firestore error code
 * @returns error message
 */
export function handleFirestoreError(error) {
  switch (error) {
    default:
      return "Fehler: Ein Fehler ist aufgetreten. Versuche es später erneut oder kontaktiere den Support.";
  }
}
